import React, { useEffect, useRef, useState } from 'react'
import { Grid, Tabs, Tab } from '@mui/material'
import axios from 'axios'
import api from '../configs/api'
import CryptoList from '../components/organisms/crypto/CryptoList'
import CryptoListOverview from '../components/organisms/crypto/CryptoListOverview'
import { useSelector } from 'react-redux'

import apiCrypto from '../data/mock/apiCrypto.json'
import ComboBoxSection from './ComboBoxSection'
import { calculateTotalByKey } from '../utility/calculations'

export default function Crypto() {
  const user = useSelector((state) => state.user)
  const walletsRef = useRef(user.wallets)
  const [barData, setBarData] = useState({ title: '', data: [] })

  const [accTable, setAccTable] = useState({})
  const [loadingOverview, setLoadingOverview] = useState(true)
  const [tabIndex, setTabIndex] = useState(0)

  const getAccumulatorWallet = (wallets) => {
    const coins = [
      ...new Set(
        wallets
          .flatMap((c) => c.coins)
          .filter((c) => c.qty > 0)
          .map((c) => c.symbol)
      ),
    ]
    let accWallet = []
    let qty = 0
    const total = wallets
      .map((c) => c.total)
      ?.reduce((acc, total) => acc + total)
    coins?.forEach((coin) => {
      qty = wallets
        .flatMap((c) => c.coins)
        .filter((c) => c.symbol === coin && c.qty > 0)
        ?.reduce((acc, c) => acc + c.qty, 0)
      accWallet.push({
        ...wallets
          .flatMap((c) => c.coins)
          .find((c) => c.symbol === coin && c.qty > 0),
        qty,
      })
    })
    return { id: 0, name: 'Overview', coins: accWallet, total }
  }

  const getRequest = async () => {
    let serverCoins = await getServerCoins()
    let s = {}
    const walletsRef = [...user?.wallets]
    if (walletsRef.length) {
      walletsRef.forEach((wallet) => {
        wallet?.coins?.forEach((cryp) => {
          s = serverCoins?.find((c) => c.symbol === cryp.symbol)
          cryp.price = s.price
          cryp.marketCap = s.marketCap
          cryp.total = s.price * cryp.qty
        })
        wallet.total = calculateTotalByKey(wallet.coins, 'total')
      })
      const accTable = getAccumulatorWallet(walletsRef)

      const bd = { title: 'Wallets', data: [] }
      walletsRef.forEach((w) => {
        bd.data.push({ amount: w.total, category: w.name })
      })

      setBarData(bd)

      setAccTable(accTable)
    }
    setLoadingOverview(false)
  }

  const getServerCoins = async () => {
    let resp = { data: {} }
    let serverCoins = []
    resp.data = await axios.get(api.external.crypto, {
      withCredentials: true,
    })
    if (resp.data?.data?.success) {
      serverCoins = resp.data.data.data
      serverCoins = serverCoins?.map(({ rank, symbol, price, marketCap }) => ({
        rank,
        symbol,
        marketCap: parseFloat(marketCap),
        price: parseFloat(price),
      }))
      return serverCoins
    }
  }

  useEffect(() => {
    getRequest(user?.wallets)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.wallets, tabIndex])

  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  return (
    <>
      <Grid container key={1} spacing={3}>
        <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
          <h1>Crypto Wallets</h1>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
          <ComboBoxSection
            listCoins={apiCrypto.data}
            wallets={walletsRef.current}
            setTabIndex={setTabIndex}
          />
        </Grid>
      </Grid>

      <Grid container key={2}>
        <Tabs value={tabIndex} onChange={handleChange}>
          <Tab label="Overview" key={1} />
          {user?.wallets.map((wallet, i) => {
            return <Tab key={2 + i} label={wallet.name} />
          })}
        </Tabs>
      </Grid>

      <Grid
        key={3}
        container
        spacing={3}
        sx={{ display: tabIndex === 0 ? 'default' : 'none', mt: 1 }}
      >
        <CryptoListOverview
          key={accTable.id}
          wallet={accTable}
          loading={loadingOverview}
          barData={barData}
        />
      </Grid>

      {user?.wallets.map((wallet, i) => {
        return (
          <Grid
            key={4 + i}
            container
            spacing={3}
            sx={{ display: tabIndex === i + 1 ? 'default' : 'none' }}
          >
            <CryptoList
              key={i + 1}
              wallet={wallet}
              loading={loadingOverview}
              listCoins={apiCrypto}
              setTabIndex={setTabIndex}
            />
          </Grid>
        )
      })}
    </>
  )
}
