//Axios requests
import config from './config'

const auth = {
  getMe: `${config.api}/auth/me`,
  logIn: `${config.api}/auth/login`,
  logout: `${config.api}/auth/logout`,
  register: `${config.api}/auth/register`,
  verifyEmail: `${config.api}/auth/verifyEmail`,
  resendToken: `${config.api}/auth/resendToken`,
  forgotPassword: `${config.api}/auth/forgotPassword`,
  resetPassword: `${config.api}/auth/resetPassword`,
}

const fins = {
  getFins: `${config.api}/fins`,
  getFin: `${config.api}/fin`,
}

const transactions = {
  getAll: `${config.api}/transactions`,
  transaction: `${config.api}/transactions`,
  upload: `${config.api}/transactions/upload`,
}

const users = {
  getAll: `${config.api}/users`,
  getOne: `${config.api}/user`,
  getPhoto: `${config.api}/public/uploads/users`,
}

const gallery = {
  getAll: `${config.api}/gallery`,
}

const download = {
  file: `${config.api}/public/uploads`,
}

const categories = {
  category: `${config.api}/categories`,
}

const external = {
  crypto: `${config.api}/crypto`,
}

const api = {
  auth,
  categories,
  fins,
  users,
  transactions,
  gallery,
  download,
  external,
}

export default api
