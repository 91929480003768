// First level: primary,secondary,error, warning, info, success, mode, tonalOffset, contrastThreshold,common,grey,text,divider,action,background
// Second Level (e.g: main.light) : light,main,dark, contrasText,disabled

export const getTheme = {
  palette: {
    primary: {
      main: 'rgb(47, 72, 88)',
    },
    secondary: {
      main: 'rgb(0, 107, 134)',
    },
    success: {
      main: 'rgb(36, 243, 13)',
    },
  },
}

//TODO Get values from _variables.scss
export const getColors = (theme) => {
  return [
    'rgb(47, 72, 88)' || theme?.palette?.primary?.main, // 'rgb(47, 72, 88)', //0   theme.palette.primary.main,
    'rgb(0, 107, 134)' || theme?.palette.secondary?.main, //'rgb(0, 107, 134)', //1  theme.palette.secondary.main,
    'rgb(0, 144, 158)', //2'
    'rgb(68, 90, 105)', //3'
    'rgb(89, 109, 121)', //4'
    'rgb(0, 122, 102)', //5'
    'rgb(0, 214, 112)', //6' //Nav Title Techbudget
    'rgb(0, 126, 106)', //7'
    'rgb(22, 156, 7)', //8'
    'rgb(28, 195, 9)', //9'
    'rgb(22, 156, 7)', //10'
    'rgb(19, 136, 6)', //11'
    'rgb(14, 97, 4)', //12
  ]
}

export const getVibrantColors = () => {
  const c = getColors()
  return [c[4], c[5], c[6], c[7], c[8], c[9], c[10], c[11], c[12]]
}

//TODO delete
export const Colors = {
  main: '#2F4858',
  secondary: '#006B86',
  green: 'rgb(36,243,13)',
  greenLight: 'rgb(0, 214, 112)',
}
