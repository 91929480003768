import React, { useRef } from 'react'

import { Container, Box, Button, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import openModal from '../../actions/openModal'
import ComboBox from '../../components/molecules/ComboBox'

export default function AddCryptoModal(props) {
  const listCoins = useRef([])
  const dispatch = useDispatch()

  const addCoinsToList = (coins) => {
    listCoins.current = coins
  }

  const updateList = () => {
    props.updateList(listCoins.current)
    cancel()
  }

  const cancel = () => {
    dispatch(openModal('close', ''))
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="p" variant="h4" sx={{ mb: 4 }}>
            Add crypto currency
          </Typography>
          <ComboBox
            label="Crypto"
            data={props.listCoins}
            updateList={addCoinsToList}
          />

          <Box
            sx={{
              mt: 4,
              mb: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{ mr: '10px' }}
              variant="contained"
              color="primary"
              onClick={updateList}
            >
              Add
            </Button>
            <Button
              color="grey"
              variant="contained"
              onClick={cancel}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}
