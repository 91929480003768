import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'

export default function TbDeleteElement(props) {
  if (props.actions.remove) {
    const disabled = !props.actions.isEditTable && !props.isEdit
    return (
      <IconButton
        edge="end"
        aria-label="delete"
        title="Delete"
        onClick={(e) => props.handleRemove(e, props)}
        disabled={disabled}
      >
        <DeleteIcon color={disabled ? 'gray' : 'error'} />
      </IconButton>
    )
  }
  return <></>
}
