import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Spinner from './utility/Spinner/Spinner'
import './App.css'

import Modal from './utility/Modal/Modal'
import NavBar from './utility/NavBar/NavBar'
import Home from './templates/Home'
import Public from './templates/Public'
import {
  primaryItemsList,
  secondaryItemsList,
  investmentItemsList,
} from './templates/MenuItemsList'

import { useSelector } from 'react-redux'
import Album from './pages/Gallery/Album'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getTheme } from './utility/theme'

const OtherRoutes = [
  {
    name: 'Album',
    route: '/gallery/:album',
    //  icon: <PhotoLibraryIcon />,
    element: <Album />,
    params: 'album',
  },
]

const ExternalRoute = (item, i) => {
  return (
    <Route exact path={`${item.route}`} key={i}>
      <Home children={item.element} />
    </Route>
  )
}
const AllElements = () => {
  const elements = []
  let index = 0
  const allList = [
    ...primaryItemsList,
    ...investmentItemsList,
    ...secondaryItemsList,
  ]

  allList.forEach((item, i) => {
    index = i
    elements.push(
      <Route exact path={`${item.route}`} key={i}>
        <Home children={item.element} />
      </Route>
    )
  })
  OtherRoutes.forEach((otherElement, i) => {
    elements.push(ExternalRoute(otherElement, index + i + 1))
  })

  return elements
}

function App() {
  const mdTheme = createTheme(getTheme)
  const { email } = useSelector((state) => state.auth)
  return (
    <ThemeProvider theme={mdTheme}>
      <Router>
        <Route path="/" component={Modal} />
        <Route path="/" component={NavBar} />
        <Suspense fallback={<Spinner />}>
          {email ? <AllElements /> : <Public />}
        </Suspense>
      </Router>
    </ThemeProvider>
  )
}

export default App
