import React, { useMemo } from 'react'
import { getComparator } from '../../../utility/objectArrays'

import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  ClickAwayListener,
} from '@mui/material'
import TbTableHead from './SortTable/TbTableHead'
import TbTableRowsContext from './SortTable/TbTableRowsContext'

export default function TbTableEdit(props) {
  const resetRowsColumns = () => {
    // setRowIndex(-1)
    // setColumnIndex(-1)
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === 'asc'
    props.setOrder(isAsc ? 'desc' : 'asc')
    props.setOrderBy(property)
  }
  const visibleRows = useMemo(
    () =>
      [...props.originalRows]
        ?.slice()
        .sort(getComparator(props.order, props.orderBy))
        .slice(
          props.page * props.rowsPerPage,
          props.page * props.rowsPerPage + props.rowsPerPage
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.order, props.orderBy, props.page, props.rowsPerPage]
  )

  return (
    <>
      <ClickAwayListener onClickAway={() => resetRowsColumns()}>
        <TableContainer>
          <Table sx={{ tableLayout: 'auto' }}>
            <TableHead>
              <TbTableHead
                headCells={props.headCells}
                actions={props.actions}
                order={props.order}
                orderBy={props.orderBy}
                createSortHandler={createSortHandler}
              />
            </TableHead>
            <TableBody>
              {visibleRows.map((row, rowIndex) => (
                <TbTableRowsContext
                  key={`${props.tableName}-${rowIndex}`}
                  row={row}
                  rowIndex={rowIndex}
                  tableName={props.tableName}
                  tableCells={props.tableCells}
                  mapValuesToColumns={props.mapValuesToColumns}
                  actions={props.actions}
                  setWallet={props.setWallet}
                  handleAction={props.handleAction}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ClickAwayListener>
    </>
  )
}
