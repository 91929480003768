export const tableCells = [
  // {
  //   column: 'checkbox',
  //   component: 'p',
  //   padding: 'checkbox',
  //   align: 'left',
  //   scope: 'row',
  //   isEdit: false,
  //   child: [
  //     {
  //       element: 'checkbox',
  //       color: 'primary',
  //       checked: '$isItemSelected',
  //       inputProps: { 'aria-labelledby': '$labelId' },
  //       sx: { mr: 3 },
  //     },
  //   ],
  // },
  {
    column: 'iconUrl',
    component: 'th',
    id: '$labelId',
    scope: 'row',
    padding: 'none',
    align: 'left',
    isEdit: false,
    type: 'picture',
    sx: { pl: '50px', width: '40px' },
    child: [
      {
        element: 'avatar',
        src: '$row.iconUrl',
        sx: {
          // position: 'absolute',
          display: 'inline-block',
          height: 20,
          width: 20,
          mt: 0,
          mr: 3,
          pl: 4,
        },
      },
      {
        element: 'typography',
        component: 'h3',
        variant: 'p',
        sx: {
          display: 'inline-block',
          textAlign: 'center',
          ml: 2,
          mt: 0,
        },
        innerRow: '$row.symbol',
      },
    ],
  },
  {
    column: 'name',
    align: 'left',
    isEdit: false,
    child: [{ innerRow: '$row.name' }],
    type: 'text',
    sx: { width: '180px' },
  },
  {
    column: 'marketCap',
    align: 'left',
    isEdit: false,
    type: 'amount',
    sx: { width: '180px' },
    child: [
      {
        innerRow: '$row.marketCap',
        formatNumber: true,
        decimals: 2,
      },
    ],
  },
  {
    column: 'price',
    type: 'amount',
    align: 'left',
    isEdit: false,
    sx: { width: '180px' },
    child: [
      {
        innerRow: '$row.price',
        formatNumber: true,
        decimals: 4,
      },
    ],
  },
  {
    column: 'qty',
    align: 'left',
    type: 'number',
    isEdit: true,
    sx: { width: '150px' },
    child: [
      {
        innerRow: '$row.qty',
        formatNumber: true,
        decimals: 0,
      },
    ],
  },
  {
    column: 'total',
    align: 'left',
    isEdit: false,
    type: 'amount',
    calculated: {
      fields: ['qty', 'price'],
      action: '*',
    },
    sx: { width: '180px' },
    child: [
      {
        innerRow: '$row.price * row.qty || 0',
        formatNumber: true,
        decimals: 2,
      },
    ],
  },
]
