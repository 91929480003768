import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Button, Box, TextField } from '@mui/material'

export default function CategoryForm(props) {
  const label = props.label || 'Option'
  const methods = useForm()

  const handleAddCategory = async (data) => {
    if (props.categories.includes(data.option)) return
    const newCategories = [...props.categories]
    newCategories.unshift(data.option)
    props.updateBasketCategories(newCategories)
    methods.setValue('option', '', { shouldValidate: false })
    methods.clearErrors('option')
  }

  const getHelperText = (methods) => {
    return methods.formState.errors.option &&
      typeof methods.formState.errors.option.message !== 'undefined'
      ? methods.formState.errors.option.message
      : ''
  }

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(handleAddCategory)}
        noValidate
      >
        <TextField
          required
          name="option"
          variant="standard"
          label={label}
          {...methods.register('option', { required: 'Field is required' })}
          error={!!methods.formState.errors.option}
          helperText={getHelperText(methods)}
        />
        <Button type="submit" variant="contained">
          Add
        </Button>
      </Box>
    </FormProvider>
  )
}
