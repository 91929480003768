import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import updateUser from '../../../actions/updateUser'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Chart from '../../Chart'
import Deposits from '../../Deposits'
import BarChart from '../../BarChart'
import { setTransactionsFields } from '../../../utility/dataExtract/transactions'
import { fetchTransactions } from '../../../utility/requests'
import DateMonthYear from '../../molecules/dates/DateMonthYear'

import {
  getCategoriesbyMonth,
  getTotalByYear,
  getGroupTotalByMonth,
} from '../../../utility/calculations'

import { Today, getDateObj } from '../../../utility/date'

export default function DashboardMonth(props) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [transactions, setTransactions] = useState([])
  const [total, setTotal] = useState()
  const [groupYear, setGroupYear] = useState([])
  const [loading, setLoading] = useState(false)
  const [myYear, setMyYear] = useState(Today.year)

  const getTransactions = async (_date) => {
    setLoading(true)
    const date = _date ? _date : Today
    const startOfMonth = `${date.year}-01-01`
    const endOfMonth = `${date.year}-12-31`

    const query = `date[gte]=${startOfMonth}&date[lte]=${endOfMonth}`
    const transactions = await fetchTransactions(query)

    setTransactions(transactions)
    setLoading(false)
    transactions.forEach((trans) => {
      //TODO THIS SHOULD COME FROM THE API
      trans = setTransactionsFields(trans)
    })
    // const totalMonth = parseFloat(getTotalMonth(transactions)).toFixed(2)
    const totalByYear = parseFloat(
      getTotalByYear(transactions, date.year)
    ).toFixed(2)
    const getAllGrouped = getCategoriesbyMonth(transactions, date.year)
    //  setTotalMonth(totalMonth) //
    setGroupYear(getAllGrouped)
    setTotal(totalByYear)
  }
  useEffect(() => {
    getTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateDate = (date) => {
    setMyYear(date.year)
    user.actualYear = date.year
    dispatch(updateUser(user))
    const thisDate = getDateObj(`${date.year}-12-31`)
    getTransactions(thisDate)
  }
  return (
    <>
      <Grid item xs={12}>
        <DateMonthYear updateDate={updateDate} views={['year']} />
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 320,
          }}
        >
          <Deposits
            title={'Total Expenses ' + myYear}
            total={total}
            loading={loading}
          />
        </Paper>
      </Grid>
      {/* Chart Year*/}
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 320,
          }}
        >
          <Chart
            title={`Total Expenses by Month ${myYear}`}
            data={getGroupTotalByMonth(transactions, myYear)}
            keyX="monthShort"
            keyY="amount"
            loading={loading}
          />
        </Paper>
      </Grid>

      {/* Chart by Category */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <BarChart
            title={'Variable Expenses'}
            data={groupYear.filter((trans) => trans.cost === 'Var')}
            loading={loading}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <BarChart
            title={'Fixed Expenses'}
            data={groupYear.filter((trans) => trans.cost === 'Fix')}
            loading={loading}
          />
        </Paper>
      </Grid>
    </>
  )
}
