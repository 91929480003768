import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import updateUser from '../../../actions/updateUser'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { setTransactionsFields } from '../../../utility/dataExtract/transactions'
import TbTableContainer from '../../../components/organisms/TbTableContainer'
import TableOrder from '../../../components/TableOrder'
import { fetchTransactions } from '../../../utility/requests'
import DateMonthYear from '../../molecules/dates/DateMonthYear'
import DashboardGroup from './DashboardGroup'

import {
  groupAmountByDateActualMonth,
  getTotalMonth,
  getCategoriesbyMonth,
  getTransactionsActualMonthByCost,
  totalExpensesPerMonth,
} from '../../../utility/calculations'

import { getDateObj } from '../../../utility/date'

export default function DashboardMonth(props) {
  const user = useSelector((state) => state.user)
  const actualDate = props.actualDate

  const dispatch = useDispatch()
  const [totalMonth, setTotalMonth] = useState(0)
  const [myLastExpenses, setMyLastExpenses] = useState([])
  const [loading, setLoading] = useState(false)

  const [myDate, setMyDate] = useState(actualDate)
  const [transactions, setTransactions] = useState([])

  const [fixCosts, setFixCosts] = useState([])
  const [varCosts, setVarCosts] = useState([])
  const [totalExpMonthVar, setTotalExpMonthVar] = useState(0)
  const [totalExpMonthFix, setTotalExpMonthFix] = useState(0)

  const [groupCategoryActualMonth, setGroupCategoryActualMonth] = useState([])

  const [groupCategoryActualMonthVar, setGroupCategoryActualMonthVar] =
    useState([])
  const [groupCategoryActualMonthFix, setGroupCategoryActualMonthFix] =
    useState([])
  const lang = props.lang

  const getTransactions = async (date) => {
    setLoading(true)
    const myDate = !date ? actualDate : getDateObj(date)
    setMyDate(myDate)
    const startOfMonth = myDate.startOfMonth
    const endOfMonth = myDate.endOfMonth
    const query = `date[gte]=${startOfMonth}&date[lte]=${endOfMonth}`
    const transactions = await fetchTransactions(query)
    setTransactions(transactions)
    setLoading(false)
    transactions.forEach((trans) => {
      //TODO THIS SHOULD COME FROM THE API
      trans = setTransactionsFields(trans)
    })
    const dateSign = `${myDate.year}-${myDate.monthNumber}`
    const totalMonth = parseFloat(
      getTotalMonth(transactions, dateSign)
    ).toFixed(2)
    const getAllGrouped = getCategoriesbyMonth(transactions, myDate.year)
    setTotalMonth(totalMonth) //
    setMyLastExpenses(
      Array.isArray(transactions)
        ? transactions.filter(
            (trans) =>
              trans.month === myDate.month && trans.year === myDate.year
          )
        : []
    )

    const varCosts = getTransactionsActualMonthByCost(
      transactions,
      'Var',
      dateSign
    )
    const fixCosts = getTransactionsActualMonthByCost(
      transactions,
      'Fix',
      dateSign
    )

    const totalExpMonthVar = totalExpensesPerMonth(
      transactions,
      'Var',
      dateSign
    )
    const totalExpMonthFix = totalExpensesPerMonth(
      transactions,
      'Fix',
      dateSign
    )
    setFixCosts(fixCosts)
    setVarCosts(varCosts)

    setTotalExpMonthVar(totalExpMonthVar)
    setTotalExpMonthFix(totalExpMonthFix)

    const groupCategoryActualMonth = getAllGrouped.filter(
      (trans) => trans.month === myDate.month
    )

    const groupCategoryActualMonthVar = getAllGrouped.filter(
      (trans) => trans.cost === 'Var' && trans.month === myDate.month
    )
    const groupCategoryActualMonthFix = getAllGrouped.filter(
      (trans) => trans.cost === 'Fix' && trans.month === myDate.month
    )
    setGroupCategoryActualMonthFix(groupCategoryActualMonthFix)
    setGroupCategoryActualMonthVar(groupCategoryActualMonthVar)
    setGroupCategoryActualMonth(groupCategoryActualMonth)
  }

  useEffect(() => {
    getTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateDate = (date) => {
    const thisDate = `${date.year}-${date.month < 10 ? `0${date.month}` : date.month}-01`
    setMyDate(thisDate)
    user.actualDate = thisDate
    dispatch(updateUser(user))
    getTransactions(thisDate)
  }

  return (
    <>
      <Grid item xs={12}>
        <DateMonthYear
          defaultValue={actualDate?.startOfMonth}
          updateDate={updateDate}
          views={['month', 'year']}
        />
      </Grid>

      {/** VARIABLE GROUP DASHBOARD */}
      <DashboardGroup
        title={`Variable Expenses`}
        loading={loading}
        chartData={{ title: `Variable Expenses Chart`, data: varCosts }}
        totalData={{ title: 'Total Variable Expenses', data: totalExpMonthVar }}
        barData={{
          title: `Variable Expenses by Category`,
          data: groupCategoryActualMonthVar,
        }}
      />

      {/** FIX GROUP DASHBOARD */}
      <DashboardGroup
        title={`Fix Expenses`}
        loading={loading}
        chartData={{ title: `Fix Expenses Chart`, data: fixCosts }}
        totalData={{ title: `Total Fix Expenses`, data: totalExpMonthFix }}
        barData={{
          title: `Fix Expenses by Category`,
          data: groupCategoryActualMonthFix,
        }}
      />

      {/** TOTAL GROUP DASHBOARD */}
      <DashboardGroup
        title={`Total Expenses`}
        loading={loading}
        chartData={{
          title: `Total Expenses Chart`,
          data: groupAmountByDateActualMonth(
            transactions,
            `${myDate.year}-${myDate.monthNumber}`
          ),
        }}
        totalData={{ title: `Total Expenses`, data: totalMonth }}
        barData={{
          title: `Total Expenses by Category`,
          data: groupCategoryActualMonth,
        }}
      />

      {/* Last Expenses*/}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <TbTableContainer
            data={myLastExpenses}
            title={`${myDate.monthName.long[lang]} Expenses`}
            loading={loading}
          >
            <TableOrder />
          </TbTableContainer>
        </Paper>
      </Grid>
    </>
  )
}
