import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import Deposits from '../../Deposits'
import CryptoTable from '../tables/SortTable/crypto/CryptoTable'
import Doughnut from '../../Doughnut'
import { getComparator } from '../../../utility/objectArrays'
import BarChart from '../../../components/BarChart'
import config from '../../../configs/config'

export default function CryptoListOverview(props) {
  let doughnutData = props?.wallet?.coins
    ?.map(({ symbol, price, qty }) => ({
      symbol,
      rate: parseInt(((price * qty) / props.wallet.total) * 100),
    }))
    .filter((c) => c.rate > 0)

  doughnutData?.sort(getComparator('desc', 'rate'))

  if (doughnutData?.length > config?.maxDoughnutElements) {
    doughnutData.length = config.maxDoughnutElements
  }
  const remainingRate = 100 - doughnutData?.reduce((acc, c) => acc + c.rate, 0)
  if (remainingRate) {
    doughnutData.push({ symbol: 'Others', rate: remainingRate })
  }

  doughnutData?.sort(getComparator('desc', 'rate'))
  const dataDoughnut = {
    labels: doughnutData?.flatMap((c) => c.symbol),
    values: doughnutData?.flatMap((c) => c.rate),
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Typography component="h2" variant="h4" color="primary">
          {props.wallet.name}
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <Paper sx={{ mt: 0, p: 2, pl: 5, pt: 1, height: 100 }}>
          <Deposits
            title={'Total Crypto'}
            total={props.wallet.total}
            loading={props.loading}
          />
        </Paper>

        <Paper
          sx={{
            mt: 3,
            pt: 0,
            pl: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 311,
            width: '100%',
          }}
        >
          <Doughnut
            loading={props.loading}
            sx={{ height: 83 }}
            data={dataDoughnut}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={12} lg={8}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <BarChart
            title={props.barData.title}
            data={props.barData.data}
            singleDataSet={true}
            loading={props.loading}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <CryptoTable
            loading={props.loading}
            coins={props.wallet.coins}
            setWallet={props.setWallet}
            actions={{
              isEditTable: false,
              hasAction: false,
              remove: false,
              edit: false,
            }}
          />
        </Paper>
      </Grid>
    </>
  )
}
