import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton } from '@mui/material'

export default function TbSaveElement(props) {
  if (props.isEdit) {
    return (
      <IconButton
        sx={{ ml: 1 }}
        edge="end"
        aria-label="save"
        title="Edit"
        onClick={(e) => props.handleSave(e, props)}
      >
        {/* <SaveIcon color="info" /> */}
        <EditIcon color="info" />
      </IconButton>
    )
  }
  return <></>
}
