import React, { useState } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import DescriptionIcon from '@mui/icons-material/Description'
import BlockIcon from '@mui/icons-material/Block'
import { Paper, Stack, Button, Box, Grid, Typography } from '@mui/material'
import TableOrder from '../components/TableOrder'
import TbTableContainer from '../components/organisms/TbTableContainer'
import { getSwal } from '../utility/notifications'
import axios from 'axios'
import api from '../configs/api'
import * as xlsx from 'xlsx'
import { getDateObj } from '../utility/date'
import Spinner from '../utility/Spinner/Spinner'

export default function Expenses() {
  const [expenses, setExpenses] = useState([])

  const [loading, setLoading] = useState(false)

  const saveTransactions = async (expenses) => {
    const resp = await axios.post(`${api.transactions.upload}`, expenses, {
      withCredentials: true,
    })
    return resp.data.data
  }

  const readUploadFile = (e) => {
    if (e.target.files) {
      setLoading(true)
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = xlsx.read(data, {
          type: 'array',
          //If true is get parsed as Date object
          cellDates: true,
        })
        const sheetNameList = workbook.SheetNames
        const json = xlsx.utils.sheet_to_json(
          workbook.Sheets[sheetNameList[0]],
          {
            // if raw true display
          }
        )

        let myData = []
        json.forEach((trans) => {
          trans.date = getDateObj(trans.date).YYYYMMDD
          if (trans.payOption) {
            trans.source = trans.payOption
          }
          myData.push(trans)
        })
        setExpenses(myData)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
    setLoading(false)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (!expenses.length) {
        getSwal('Error', 'Please upload a spreadsheet file')
        return
      }
      await saveTransactions(expenses)
      getSwal('Success', 'Your File data has been uploaded', 'success')
    } catch (error) {
      if (error.response.data.error) {
        getSwal('Error', error.response.data.error)
      }
    }
  }

  const cancelUpload = (e) => {
    setExpenses([])
  }

  const onDownload = () => {
    const link = document.createElement('a')
    //TODO: replace hardcoded value
    const fileName = `expenses_template_TechBudget.xlsx`
    link.download = fileName //
    link.href = `${api.download.file}/${fileName}`
    link.click()
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} lg={9}>
          <h1>Upload Expenses</h1>
        </Grid>
        <br />
        <br />
        <Grid item xs={12} md={10} lg={10}>
          {expenses.length ? (
            <Paper
              sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column' }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <TbTableContainer
                  title={'Uploaded File'}
                  data={expenses}
                  loading={loading}
                >
                  <TableOrder />
                </TbTableContainer>
              )}
            </Paper>
          ) : (
            <Box
              sx={{ mt: 2, pl: 0, display: 'flex', flexDirection: 'column' }}
            >
              <Stack direction="column" alignItems="left" spacing={2}>
                <Box sx={{ fontWeight: '500' }}>
                  <h3>
                    Please upload Expenses spread sheet, with the following
                    headers:
                  </h3>
                  <p> (Don't remove the headers)</p>

                  <Typography
                    component="p"
                    variant="p"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    | date | name | description | category | payOption | amount
                    |
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    <b> Important: </b>On "Payment Option" (payOption), please
                    input only: Cash, Debit or Credit
                  </Typography>
                  {/* <h4>
                    These are alternative fields, not required to be filled
                  </h4>
                  <p>source | cost</p> */}
                  <Typography
                    component="p"
                    variant="p"
                    color="main"
                    sx={{ mt: 2 }}
                  >
                    Please downdload the template file and fill all the fields,
                    except for description which is optional
                  </Typography>
                </Box>

                <Button
                  onClick={onDownload}
                  variant="contained"
                  color="secondary"
                  sx={{ maxWidth: '140px' }}
                  startIcon={<DescriptionIcon />}
                >
                  Download
                </Button>
              </Stack>
            </Box>
          )}

          <br />
          <Box
            component="form"
            onSubmit={(e) => handleSubmit(e)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <label htmlFor="upload">
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  startIcon={<UploadFileIcon />}
                >
                  Upload
                </Button>
                <input
                  id="upload"
                  hidden
                  type="file"
                  multiple
                  onChange={(e) => readUploadFile(e)}
                  accept=".xlsx, .xls, .csv"
                  onClick={(e) => (e.target.value = null)}
                />
              </label>
              <label htmlFor="cancel">
                <Button
                  id="cancel"
                  variant="contained"
                  component="span"
                  onClick={(e) => cancelUpload(e)}
                  color="grey"
                  endIcon={<BlockIcon />}
                >
                  Erase
                </Button>
              </label>
              <label htmlFor="submit">
                <Button
                  id="submit"
                  type="submit"
                  variant="contained"
                  sx={{ ml: 2 }}
                >
                  Save
                </Button>
              </label>
            </Stack>
          </Box>
        </Grid>
        <br />
        <br />
      </Grid>
    </>
  )
}
