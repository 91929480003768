import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton } from '@mui/material'

export default function TbEditElement(props) {
  if (!props.isEdit) {
    const disabled = !props.actions.isEditTable && !props.isEdit
    return (
      <IconButton
        sx={{ ml: 1 }}
        edge="end"
        aria-label="edit"
        title="Edit"
        onClick={(e) => props.handleEdit(e, props)}
        disabled={disabled}
      >
        <EditIcon color={disabled ? 'grey' : 'secondary'} />
      </IconButton>
    )
  }
  return <></>
}
