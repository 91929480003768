import { getColors, getVibrantColors } from './theme'
import { monthNames } from './date'
import config from '../configs/config'

const getUniqueValueFromArray = (data, key) => {
  return data
    .map((val) => val[key])
    .filter((value, i, array) => array.indexOf(value) === i)
}

const getCategoryAmountArray = (data, category) => {
  let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  const dataCategory = data.filter((trans) => trans.category === category)
  dataCategory.forEach((trans) => {
    if (trans.category === category) {
      arr[trans.month - 1] = parseFloat(trans.amount).toFixed(2)
    }
  })
  return arr
}

const createValueArraysByGroup = (data, groupBy) => {
  const categories = getUniqueValueFromArray(data, groupBy)
  const obj = {}
  categories.forEach((category, i) => {
    obj[category] = getCategoryAmountArray(data, category)
  })
  return obj
}

export const createDataSetByGroup = (theme, data, groupBy) => {
  if (!data) return false
  const categoriesValueArrays = createValueArraysByGroup(data, groupBy)
  const colors =
    data.length < config.minVibrantColors
      ? getVibrantColors()
      : getColors(theme)
  let arr = []
  Object.keys(categoriesValueArrays).forEach((category, i) => {
    arr.push({
      label: category,
      data: categoriesValueArrays[category],
      backgroundColor: colors[i] ? colors[i] : 'orange',
    })
  })
  return arr
}

export const createBarChartData = (theme, data) => {
  const labels = monthNames.short.en
  const datasets = createDataSetByGroup(theme, data, 'category')
  return { labels, datasets }
}

export const createBarChartDataSingle = (theme, data) => {
  const colors =
    data.length < config.minVibrantColors
      ? getVibrantColors()
      : getColors(theme)
  return {
    labels: data.map((item) => item.category),
    datasets: data.map((item, i) => {
      const dataMatrix = []
      dataMatrix.length = data.length
      dataMatrix[i] = item.amount
      return {
        label: item.category,
        data: dataMatrix,
        backgroundColor: colors[i],
        maxBarThickness: 30,
      }
    }),
  }
}
