import React from 'react'
import {
  Avatar,
  Checkbox,
  TableCell,
  TextField,
  Tooltip,
  Box,
} from '@mui/material'

const tooltipTitle = (row, column) => {
  const text = 'original: ' + row[`${column}Prev`]
  return typeof row[`${column}Prev`] === 'undefined' ||
    row[`${column}Prev`] === row[column]
    ? ''
    : text
}

/**
 *
 * TablecellInteractive is an editable cell, if clicked it is an input field
 * @param {*} props = {rowIndex,columnIndex,row,name}
 * @returns
 */
function TablecellInteractive(props) {
  const cond = props.isColEditable && props.isEdit

  const colorChange = () => {
    return 'primary'
  }

  const handleValue = (prev, value) => {
    props.handleValue({
      prev,
      value,
      column: props.column,
    })
  }

  const ElementEdit = (props) => {
    if (props.type === 'picture') return <Avatar src={props.row.iconUrl} />
    return (
      <TextField
        defaultValue={props.value}
        onChange={(e) => handleValue(props.value, e.target.value)}
      />
    )
  }

  const ElementDisplay = (props) => {
    if (props.type === 'picture') {
      return <Avatar src={props.row.iconUrl} />
    }
    if (props.type === 'checkbox') {
      return (
        <Checkbox
          key={props.row.id}
          checked={props.isItemSelected}
          inputProps={{
            'aria-labelledby': props.row.id,
          }}
        ></Checkbox>
      )
    }
    if (props.type === 'picture')
      return (
        <Avatar
          key={props.row.id}
          src={props.row.iconUrl}
          sx={{ height: '30px', width: '30px' }}
        ></Avatar>
      )
    return (
      <Box>{props.mapValuesToColumns(props.row, props.column, undefined)}</Box>
    )
  }

  return (
    <TableCell key={props?.rowIndex} sx={props?.sx}>
      {cond ? (
        <ElementEdit {...props} />
      ) : (
        <Tooltip
          title={tooltipTitle(props.row, [props.column])}
          placement="top-start"
          sx={{ display: 'none' }}
          arrow
        >
          <Box
            sx={{
              color: colorChange,
            }}
          >
            <ElementDisplay {...props} />
          </Box>
        </Tooltip>
      )}
    </TableCell>
  )
}

export default TablecellInteractive
