import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
// import BarChartIcon from '@mui/icons-material/BarChart'
//import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'

import ReceiptIcon from '@mui/icons-material/Receipt'
import EditIcon from '@mui/icons-material/Edit'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import PaymentIcon from '@mui/icons-material/Payment'
import BallotIcon from '@mui/icons-material/Ballot'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin'

import Expenses from '../pages/Expenses'
import Crypto from '../pages/Crypto'
//import Stocks from '../pages/Stocks'
import User from '../pages/User'
import Dashboard from '../pages/Dashboard'
import Category from '../pages/Category'
import PaymentMethod from '../pages/PaymentMethod'
//import Gallery from '../pages/Gallery/Gallery'
import AddTransaction from '../pages/AddTransaction'
import EditExpenses from '../pages/EditExpenses'

export const primaryItemsList = [
  {
    name: 'Dashboard',
    route: '/',
    icon: <DashboardIcon />,
    element: <Dashboard />,
  },
  {
    name: 'Add Expenses',
    route: '/addtransaction',
    icon: <ReceiptIcon />,
    element: <AddTransaction />,
  },
  {
    name: 'Upload Expenses',
    route: '/expenses',
    icon: <UploadFileIcon />,
    element: <Expenses />,
  },

  {
    name: 'Edit & Export',
    route: '/editexpenses',
    icon: <EditIcon />,
    element: <EditExpenses />,
  },
  //TODO: Implement
  // {
  //   name: 'Gallery',
  //   route: '/gallery',
  //   icon: <PhotoLibraryIcon />,
  //   element: <Gallery />,
  // },
]

export const investmentItemsList = [
  {
    name: 'Crypto Wallet',
    route: '/crypto',
    icon: <CurrencyBitcoinIcon />,
    element: <Crypto />,
  },
  //TODO: Implement
  // {
  //   name: 'Stocks',
  //   route: '/stocks',
  //   icon: <BarChartIcon />,
  //   element: <Stocks />,
  // },
]

export const secondaryItemsList = [
  {
    name: 'User',
    route: '/user',
    icon: <PeopleIcon />,
    element: <User />,
  },
  {
    name: 'Categories',
    route: '/categories',
    icon: <BallotIcon />,
    element: <Category />,
  },
  {
    name: 'Payment Method',
    route: '/paymentmethod',
    icon: <PaymentIcon />,
    element: <PaymentMethod />,
  },
]
