import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import params from '../configs/params'

import DashboardMonth from '../components/organisms/dashboards/DashboardMonth'
import DashboardMonthYear from '../components/organisms/dashboards/DashboardMonthYear'
import { Today, getDateObj } from '../utility/date'

export default function Dashboard() {
  //TODO Replace with i18n
  const lang = params.lang

  const user = useSelector((state) => state.user)

  const actualDate = user?.actualDate ? getDateObj(user?.actualDate) : Today

  const actualYear = user?.actualYear
    ? getDateObj(user?.actualYear)
    : Today?.year

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <h1>Dashboard</h1>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <h2>Monthly Expenses</h2>
      </Grid>

      <DashboardMonth actualDate={actualDate} lang={lang} />

      {/* Total Expenses Year */}
      <Grid item xs={12} md={12} lg={12} sx={{ mt: 7 }}>
        <h2>Yearly Expenses</h2>
      </Grid>

      <DashboardMonthYear actualYear={actualYear} lang={lang} />
    </Grid>
  )
}
