import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import setTransactions from '../actions/setTransactions'
import { Paper, Button, Grid, Box } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import TbTableContainerLegacy from '../components/organisms/TbTableContainerLegacy'
import TableExcelLegacy from '../components/organisms/tables/TableExcelLegacy'
import * as xlsx from 'xlsx'
import config from '../configs/config'
import api from '../configs/api'
import axios from 'axios'
import { getSwal } from '../utility/notifications'
import { transFormObjectToArray } from '../utility/objectArrays'
import { fetchTransactions } from '../utility/requests'
import Spinner from '../utility/Spinner/Spinner'
import Swal from 'sweetalert2'
import { Today, getDateObj } from '../utility/date'
import DateMonthYear from '../components/molecules/dates/DateMonthYear'

export default function EditExpenses() {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const mytrans = useRef([])
  const [editExpensesDate, setEditExpensesDate] = useState(
    user?.editExpensesDate
  )

  const [loading, setLoading] = useState(false)

  const transactions = useSelector((state) => state.transactions)

  const _editExpensesDate = user?.editExpensesDate
    ? getDateObj(user?.editExpensesDate)
    : Today

  useEffect(() => {
    getTransactions()
  }, [])

  // const getTransactions = async (date) => {
  //   setLoading(true)

  //   const myDate = !date ? Today : getDateObj(date)
  //   //TODO: Refactor this code
  //   const startOfMonth = myDate.startOfMonth
  //   const endOfMonth = myDate.endOfMonth
  //   const query = `date[gte]=${startOfMonth}&date[lte]=${endOfMonth}`
  //   const trans = await fetchTransactions(query)
  //   setLoading(false)
  //   dispatch(setTransactions(trans))
  // }

  const getTransactions = async (date) => {
    setLoading(true)

    setEditExpensesDate(_editExpensesDate)
    const myDate = !date
      ? _editExpensesDate
        ? _editExpensesDate
        : Today
      : getDateObj(date)
    //TODO: Refactor this code
    const startOfMonth = myDate.startOfMonth
    const endOfMonth = myDate.endOfMonth
    const query = `date[gte]=${startOfMonth}&date[lte]=${endOfMonth}`
    const trans = await fetchTransactions(query)
    trans.forEach((tr, i) => {
      tr.rank = i + 1
      tr.category = tr.category.name
      tr.id = tr._id
    })

    setLoading(false)
    dispatch(setTransactions(trans))
  }

  const updateTransaction = (data) => {
    const editTrans = mytrans.current.filter((trans) =>
      trans.id.includes(data.id)
    )
    if (!editTrans.length) {
      mytrans.current.push(data)
    } else {
      const index = mytrans.current.findIndex((trans) =>
        trans.id.includes(data.id)
      )
      mytrans.current[index] = data
    }
  }

  const saveExcel = async (e, transactions) => {
    const categories = user.categories
    const sources = user.sources
    if (!mytrans.current.length) {
      getSwal('Error', 'Please update at least one expenses')
      return
    }
    //TODO: Refactor and improve this logic
    //Check valid categories
    let isValid = true
    let invalidData = []
    mytrans.current.forEach((trans) => {
      trans.category = trans.category.trim()
      if (!categories.includes(trans.category)) {
        isValid = false
        invalidData.push(trans.category)
      }
      if (!sources.includes(trans.source)) {
        isValid = false
        invalidData.push(trans.source)
      }
    })
    try {
      if (isValid) {
        await updateTransactions(mytrans.current, transactions)
        // setTimeout(() => {
        getSwal(
          'Updated',
          'Your expenses were successfully updated!',
          'success'
        )
        //}, config.timeInterval)
      } else {
        const msg =
          invalidData.length === 1
            ? 'This value is not valid:'
            : 'These values are not valid:'
        getSwal('Error', msg + invalidData.join(','))
        await getTransactions()
      }
    } catch (error) {
      getSwal('Error', 'There was an error updating your records', error)
    }
    mytrans.current = []
  }

  const removeTransaction = async (transaction, transactions) => {
    const { id, name, description, amount } = transaction

    const result = await Swal.fire({
      title: 'Delete Expenses',
      text: `You are about to delete: ${name} ${description} $${amount}`,
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      closeOnConfirm: false,
    })
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      try {
        const url = `${api.transactions.transaction}/${id}`
        const { data } = await axios.delete(url, {
          withCredentials: true,
        })
        if (data.success) {
          Swal.fire('Deleted!', 'Your transactions was deleted', 'success')
          await getTransactions()
        }
      } catch (error) {
        Swal.fire('Error deleting', 'Please try again!', 'error')
      }
    } else if (result.isDenied) {
      Swal.fire('Changes were not saved', '', 'info')
    }
  }

  //TODO: Refactor to requests
  const updateTransactions = async (data, transactions) => {
    try {
      const resp = await axios.put(`${api.transactions.transaction}`, data, {
        withCredentials: true,
      })
      if (resp.data.success) {
        await getTransactions()
      }
    } catch (error) {
      console.error('Edit Expenses error', error)
    }
  }

  const handleOnExport = () => {
    let wb = xlsx.utils.book_new()
    const myTrans = transFormObjectToArray(transactions)
    let ws = xlsx.utils.json_to_sheet(myTrans)
    xlsx.utils.book_append_sheet(wb, ws, config.exportFile.sheet)
    xlsx.writeFile(
      wb,
      `${config.exportFile.name}.${config.exportFile.extension}`
    )
  }

  const updateDate = (date) => {
    const thisDate = `${date.year}-${date.month < 10 ? `0${date.month}` : date.month}-01`
    getTransactions(thisDate)
  }

  return (
    <Grid container>
      <Grid item xs={12} md={8} lg={9}>
        <Box>
          <h1>Edit Expenses</h1>
          <p>
            Click on any cell to edit your expenses, then click the "Update"
            button to update your results, and "Export" button in case you would
            like to download the table into a spread sheet file.
          </p>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {editExpensesDate?.startOfMonth ? (
          <DateMonthYear
            defaultValue={editExpensesDate?.startOfMonth}
            updateDate={updateDate}
            views={['month', 'year']}
          />
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Box
          sx={{
            mt: 3,
            p: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <Button
            onClick={handleOnExport}
            variant="contained"
            color="secondary"
            sx={{ maxWidth: '140px' }}
            startIcon={<DescriptionIcon />}
          >
            Export
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{ mt: 3, p: 2, display: 'flex', flexDirection: 'column' }}>
          {loading ? (
            <Spinner />
          ) : (
            <TbTableContainerLegacy
              title={'Edit Your Expenses'}
              transactions={transactions}
              data={transactions}
            >
              <TableExcelLegacy
                updateTransaction={updateTransaction}
                removeTransaction={removeTransaction}
              />
            </TbTableContainerLegacy>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          onClick={(e) => saveExcel(e, transactions)}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  )
}
