const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export const transFormObjectToArray = (transactions) => {
  if (!Array.isArray(transactions)) {
    const length = Object.keys(transactions).length
    let transArray = []
    for (let i = 0; i < length; i++) {
      transArray.push(transactions[i])
    }
    return transArray
  }
  return transactions
}

export const removeObjectWithId = (arr, id) => {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id)
  arr.splice(objWithIdIndex, 1)
  return arr
}

// Example usage:
/**

const array1 = [
  { id: 1, name: 'John' },
  { id: 2, name: 'Alice' },
]
const array2 = [
  { id: 2, age: 30 },
  { id: 3, name: 'Bob' },
]


const mergedArray = mergeArrays(array1, array2, 'id')
console.log(mergedArray)

*/
export const mergeArrays = (arr1, arr2, key) => {
  const merged = []

  // Create a map for faster lookups
  const map = new Map()
  arr1.forEach((item) => map.set(item[key], item))

  arr2.forEach((item) => {
    const existingItem = map.get(item[key])
    if (existingItem) {
      // Merge properties of arr2 into arr1 if key exists
      Object.assign(existingItem, item)
    } else {
      // If key does not exist in arr1, add the item to the merged array
      merged.push(item)
    }
  })

  // Add remaining items from arr1 to the merged array
  arr1.forEach((item) => {
    if (!map.has(item[key])) {
      merged.push(item)
    }
  })

  return merged
}
