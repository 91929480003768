import React from 'react'

import { visuallyHidden } from '@mui/utils'
import { TableCell, TableRow, TableSortLabel, Box } from '@mui/material'

export default function TbTableHead(props) {
  return (
    <TableRow>
      {props.headCells?.map((field, i) => {
        return (
          <TableCell key={i} sx={field?.sx || {}}>
            <TableSortLabel
              active={props.orderBy === field.column}
              direction={props.orderBy === field.column ? props.order : 'asc'}
              onClick={props.createSortHandler(field.column)}
            >
              <Box
                sx={{
                  color:
                    //field.isEdit && props.isEdit.current ? 'green' :
                    'primary',
                }}
              >
                {field.label}
              </Box>
              {props.orderBy === field.column ? (
                <Box component="span" sx={visuallyHidden}>
                  {props.order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )
      })}
      {props.actions.hasAction ? (
        <TableCell sx={{ width: '150px', pl: 3 }} key={props.headCells.length}>
          Actions
        </TableCell>
      ) : (
        ''
      )}
    </TableRow>
  )
}
