import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import setBill from '../../actions/setBill'
import resetBill from '../../actions/resetBill'
import api from '../../configs/api'
import axios from 'axios'
import moment from 'moment'
import Title from '../Title'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import AmountElement from '../FormElement/AmountElement'
import SelectElement from '../FormElement/SelectElement'
import CategoriesElement from '../FormElement/CategoriesElement'
import { getSwal } from '../../utility/notifications'
import { fetchUser } from '../../utility/requests'
import { getCategoryRules } from '../../utility/rules'
import { validateDate } from '../../utility/validations'
import { Today, getDateObj } from '../../utility/date'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { Paper } from '@mui/material'

export default function TransactionForm(props) {
  const dispatch = useDispatch()
  const bill = useSelector((state) => state.bill)
  const methods = useForm()
  const [categories, setCategories] = useState('')
  const [category, setCategory] = useState('')
  const [name, setName] = useState('')
  const [amount, setAmount] = useState('')
  const [sources, setSources] = useState('')
  const [disabledBtn, setDisabledBtn] = useState(false)

  const dateRefYMD = useRef(`${Today.year}-${Today.monthNumber}-${Today.day}`)

  const onCloseDate = () => {
    methods.register('date', { required: 'Date is required' })
    methods.setValue('date', dateRefYMD.current)
  }

  const onChangeDate = (val) => {
    const myDate = getDateObj(val.$d)
    dateRefYMD.current = myDate.YYYYMMDD
  }

  useEffect(() => {
    clearValues(methods)
    const fetchUserFn = async () => {
      const user = await fetchUser()
      let categories = []
      user.categories.forEach((cat, i) => {
        categories.push({ id: i, name: cat })
      })
      setSources(user.sources)
      setCategory(categories[0].name)
      setCategories(categories)
    }
    fetchUserFn()
    const now = moment().format('YYYY-MM-DD')
    methods.setValue('date', now)

    dispatch(resetBill())
    setBill({ ...bill, date: now })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (bill.amount) {
      setAmount(bill.amount)
      methods.setValue('amount', bill.amount)
    }
  }, [methods, bill.amount])

  useEffect(() => {
    if (bill.name) {
      setName(bill.name)
      methods.setValue('name', bill.name)
    }
  }, [methods, bill.name])

  useEffect(() => {
    if (bill.date) {
      const date = validateDate(bill.date) ? bill.date : Today.YYYYMMDD
      methods.setValue('date', date)
    }
  }, [bill.date, methods])

  const createTransaction = async (formData) => {
    const resp = await axios.post(`${api.transactions.transaction}`, formData, {
      withCredentials: true,
    })
    return resp.data.data
  }

  const fiscalData = (data) => {
    const amount = parseFloat(data.amount)
    const rules = getCategoryRules(data)
    if (amount > rules.max) {
      getSwal(
        'Value too high!',
        `Value cannot be higher than $${parseFloat(rules.max).toFixed(
          2
        )} for this category`
      )
      return false
    }
    return true
  }

  const onSubmit = async (data) => {
    try {
      setDisabledBtn(true)
      const result = fiscalData(data)
      //TODO: Temporary hack
      data.cost = data.cost === 'Variable' ? 'Var' : 'Fix'
      if (result) {
        await createTransaction(data)
        getSwal('Success', 'Your transaction has been saved', 'success')
      }
    } catch (error) {
      console.error('ERRROROOOOR', error)
      getSwal(
        'Error',
        'There was an error attempting to save your transaction, please try again!'
      )
    }
    setDisabledBtn(false)
  }

  const clearValues = (methods) => {
    //methods.reset({})
    methods.setValue('date', '')
    methods.setValue('name', '')
    methods.setValue('amount', '')
  }

  const getError = (methods, propsName) => {
    return !!methods.formState.errors[propsName]
  }
  const getHelpError = (methods, propsName) => {
    return methods.formState.errors[propsName] &&
      typeof methods.formState.errors[propsName].message !== 'undefined'
      ? methods.formState.errors[propsName].message
      : ''
  }

  //maxWidth="xs"
  return (
    <FormProvider {...methods}>
      <Title>Single Expense</Title>
      <br></br>
      <Paper sx={{ maxWidth: '400px', pl: 2, pr: 2, pt: 2, pb: 4 }}>
        <Container sx={{ ml: '0', pl: '0', p: 0 }}>
          <Box
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
            sx={{
              marginTop: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
            noValidate
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  sx={{ width: '100%' }}
                  label="Date"
                  views={['year', 'month', 'day']}
                  format="YYYY-MM-DD"
                  defaultValue={dayjs(Today.YYYYMMDD)}
                  onChange={(val) => onChangeDate(val)}
                  onClose={onCloseDate}
                  disableFuture
                />
              </DemoContainer>
            </LocalizationProvider>

            <TextField
              required
              name="name"
              variant="standard"
              label="Name"
              {...methods.register('name', { required: 'Field is required' })}
              error={getError(methods, 'name')}
              helperText={getHelpError(methods, 'name')}
              //We add these lines to avoid antifocus with label
              autoComplete={name}
              // defaultValue={name}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              label="Description"
              variant="standard"
              {...methods.register('description')}
            />
            <AmountElement
              amount={amount}
              errors={methods.formState.errors.amount}
            />
            <br />
            <FormControl>
              <InputLabel required id="select-category" sx={{ ml: '-14px' }}>
                Category
              </InputLabel>
              {category ? (
                <CategoriesElement
                  categories={categories}
                  category={category}
                  setMyCategory={(e) => setCategory(e.target.value)}
                />
              ) : (
                ''
              )}
            </FormControl>
            <br />
            <FormControl>
              <InputLabel id="select-cost" required sx={{ ml: '-14px' }}>
                Cost
              </InputLabel>
              <SelectElement options={['Variable', 'Fixed']} name="cost" />
            </FormControl>
            <FormControl sx={{ display: 'none' }}>
              <InputLabel id="select-cost" required sx={{ ml: '-14px' }}>
                Type
              </InputLabel>
              <SelectElement
                options={['Expenses', 'Transaction', 'Revenue']}
                name="type"
              />
            </FormControl>
            <br />
            <FormControl>
              <InputLabel id="select-source" required sx={{ ml: '-14px' }}>
                Payment Option
              </InputLabel>
              {sources.length ? (
                <SelectElement options={sources} name="source" />
              ) : (
                ''
              )}
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={disabledBtn}
            >
              Save
            </Button>
          </Box>
        </Container>
      </Paper>
    </FormProvider>
  )
}
