import { formatNumber } from '../../../../../utility/format'

//TODO: temporary solution for this specific, case. Then do it more abstract
export const mapValuesToColumns = (row, column, element) => {
  if (column === 'checkbox') {
    return undefined
  }
  if (column === 'symbol' && element === 'typography') {
    return row.symbol
  }
  if (column === 'symbol' && element === 'avatar') {
    return row.iconUrl
  }
  if (column === 'name') {
    return row.name
  }
  if (column === 'marketCap') {
    return `$${formatNumber(row.marketCap, 2)}`
  }
  if (column === 'price') {
    return `$${formatNumber(row.price, 4)}`
  }
  if (column === 'qty') {
    return formatNumber(row.qty)
  }
  if (column === 'total') {
    return `$${formatNumber(row.price * row.qty || 0, 2)}`
  }
}
