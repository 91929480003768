import React, { useState, useRef, useEffect, memo } from 'react'
import { Grid, Paper, TextField, Typography } from '@mui/material'
import Deposits from '../../Deposits'
import CryptoTable from '../tables/SortTable/crypto/CryptoTable'
import { useDispatch, useSelector } from 'react-redux'
import updateUser from '../../../actions/updateUser'
import api from '../../../configs/api'
import axios from 'axios'
import { getSwal } from '../../../utility/notifications'
import Swal from 'sweetalert2'
import CryptoListButtons from './CryptoListIconButtons'
import openModal from '../../../actions/openModal'
import AddCryptoModal from '../../../components/crypto/AddCryptoModal'

function CryptoList(props) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const wallet = useRef(props.wallet)
  const [ntotal, setNtotal] = useState(props.wallet.total)
  const [coins, setCoins] = useState(props.wallet.coins)
  const [name, setName] = useState(props.wallet.name)
  const editName = useRef(props.wallet.name)
  const [loading, setLoading] = useState(props.loading)

  useEffect(() => {
    if (props.wallet.coins.length) {
      setLoading(false)
    }
  }, [props.wallet.coins.length])

  const [isEditTable, setIsEditTable] = useState(false)

  const calculateTotal = (coins) => {
    let total = 0
    if (coins.length) {
      total = coins
        .map((c) => c.price * c.qty)
        .reduce((total, num) => total + num)
    }
    return total
  }

  const handleCancel = () => {
    wallet.current.coins.forEach((obj, i) => {
      Object.keys(obj).forEach((key) => {
        if (key.endsWith('Prev')) {
          const mainKey = key.replace('Prev', '')
          if (obj.hasOwnProperty(mainKey)) {
            const prevValue = wallet.current.coins[i][mainKey + 'Prev']
            wallet.current.coins[i][mainKey] = prevValue
            delete wallet.current.coins[i][mainKey + 'Prev']
          }
        }
      })
    })
    setIsEditTable(false)
  }

  const handleRemove = async (coin) => {
    const result = await Swal.fire({
      title: `Delete ${wallet.current.name}`,
      text: `You are about to delete this wallet , are you sure?`,
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      closeOnConfirm: false,
    })
    if (result.isConfirmed) {
      try {
        setLoading(true)
        const userUpdated = await updateThisUser({
          ...user,
          wallets: user.wallets.filter((w) => w.id !== wallet.current.id),
        })
        dispatch(updateUser(userUpdated))
        setLoading(false)
        Swal.fire('Deleted!', 'Your wallet was deleted', 'success')
        props.setTabIndex(0)
      } catch (error) {
        Swal.fire('Error deleting', 'Please try again!', 'error')
      }
    } else if (result.isDenied) {
      Swal.fire('Changes were not saved', '', 'info')
    }
  }

  const updateThisUser = async (formData) => {
    const resp = await axios.put(`${api.users.getAll}/${user._id}`, formData, {
      withCredentials: true,
    })
    return resp.data.data
  }

  const handleAdd = (e) => {
    openAddCryptoModal(user, props)
  }

  const handleSave = async (e) => {
    //Delete Prev keys
    wallet.current.coins.forEach((obj, i) => {
      Object.keys(obj).forEach((key) => {
        if (key.endsWith('Prev')) {
          const mainKey = key.replace('Prev', '')
          if (obj.hasOwnProperty(mainKey)) {
            delete wallet.current.coins[i][mainKey + 'Prev']
          }
        }
      })
    })

    const total = calculateTotal(wallet.current.coins)

    const userSave = { ...user }
    userSave.wallets.find((w) => w.id === wallet.current.id).coins =
      wallet.current.coins
    userSave.wallets.find((w) => w.id === wallet.current.id).total = total

    userSave.wallets.find((w) => w.id === wallet.current.id).name =
      editName.current

    try {
      setName(editName.current)
      await updateThisUser(userSave)
      dispatch(updateUser(userSave))
      getSwal(
        'Wallet Updated!',
        `The Wallet has been updated successfully!`,
        'success'
      )
      setIsEditTable(false)
    } catch (error) {
      getSwal('Error', `There was an issue saving this wallet`)
    }
  }

  const setWallet = (updatedRow) => {
    wallet.current.coins.find((c) => c.id === updatedRow.id).qty =
      updatedRow.qty
    const total = calculateTotal(wallet.current.coins)
    setNtotal(total)
  }

  const removeRow = async (data) => {
    const result = await Swal.fire({
      title: `Delete ${data.row.name}`,
      text: `Are you sure you want to delete this crypto currency?`,
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      closeOnConfirm: false,
    })
    if (result.isConfirmed) {
      try {
        setLoading(true)
        //Clone all wallets
        const userWallets = [...user.wallets].map((obj) => ({ ...obj }))

        const thisWallet = userWallets.find((w) => w.id === props.wallet.id)

        const index = userWallets.findIndex((w) => w.id === props.wallet.id)

        userWallets[index] = {
          ...thisWallet,
          coins: thisWallet.coins.filter((c) => c.id !== data.row.id),
        }

        const userUpdated = await updateThisUser({
          ...user,
          wallets: userWallets,
        })

        setCoins(thisWallet.coins.filter((c) => c.id !== data.row.id))
        const total = calculateTotal(
          thisWallet.coins.filter((c) => c.id !== data.row.id)
        )

        setNtotal(total)
        dispatch(updateUser(userUpdated))
        setLoading(false)
        Swal.fire('Deleted!', 'Your crypto was deleted', 'success')
      } catch (error) {
        console.error('error: ', error)
        Swal.fire('Error deleting', 'Please try again!', 'error')
      }
    } else if (result.isDenied) {
      Swal.fire('Changes were not saved', '', 'info')
    }
  }

  const handleRowAction = async (data) => {
    if (data.action === 'save') {
      const total = calculateTotal(coins)

      const userSave = { ...user }
      userSave.wallets.find((w) => w.id === wallet.current.id).coins = coins
      userSave.wallets.find((w) => w.id === wallet.current.id).total = total

      userSave.wallets.find((w) => w.id === wallet.current.id).name =
        editName.current

      try {
        await updateThisUser(userSave)
      } catch (error) {
        getSwal('Error', `There was an issue saving adding this crypto`)
      }
    } else if (data.action === 'delete') {
      removeRow(data)
    }
  }

  const handleValue = (name, value) => {
    editName.current = value
  }

  const openAddCryptoModal = async (user, props) => {
    const coinsOnTable = props.wallet.coins.map((c) => c.symbol)
    const data = props.listCoins.data.filter(
      (coin) => !coinsOnTable.includes(coin.symbol)
    )

    dispatch(
      openModal(
        'open',
        <AddCryptoModal listCoins={data} user={user} updateList={updateList} />
      )
    )
  }

  const updateList = async (coinsFromList) => {
    const userWallets = [...user.wallets].map((obj) => ({ ...obj }))
    const coinsFromListFormatted = coinsFromList.map(
      ({ rank, symbol, name, iconUrl, price, qty, marketCap, total }) => ({
        id: rank,
        iconUrl,
        symbol,
        name,
        price,
        qty: 0,
        marketCap,
        total: 0,
      })
    )

    const coinsUpdated = [...wallet.current.coins, ...coinsFromListFormatted]

    wallet.current.coins = coinsUpdated
    userWallets.find((w) => w.id === wallet.current.id).coins = coinsUpdated
    try {
      setLoading(true)
      const userUpdated = await updateThisUser({
        ...user,
        wallets: userWallets,
      })
      setCoins(coinsUpdated)
      dispatch(updateUser(userUpdated))
      setIsEditTable(false)
      Swal.fire('Updated!', 'Your coins where added to your wallet', 'success')
    } catch (error) {
      console.error('error: ', error)
      Swal.fire('Error adding coins', 'Please try again!', 'error')
    }
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={12} sx={{ mt: 4, mb: -6, pb: 0 }}>
        {isEditTable ? (
          <TextField
            defaultValue={name}
            sx={{ fontSize: '3em' }}
            onChange={(e) => handleValue(name, e.target.value)}
          />
        ) : (
          <Typography component="h2" variant="h4" color="primary">
            {name}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        lg={12}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 0,
          mt: 0,
          ml: 0,
          mr: 0,
          mb: -2,
        }}
      >
        <CryptoListButtons
          isEditTable={isEditTable}
          handleCancel={handleCancel}
          handleRemove={handleRemove}
          handleSave={handleSave}
          handleAdd={handleAdd}
          setIsEditTable={setIsEditTable}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} sx={{ marginTop: 0, paddingTop: 0 }}>
        <Paper
          sx={{
            marginTop: 0,
            paddingTop: 0,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 120,
          }}
        >
          <Deposits title={'Total Crypto'} total={ntotal} loading={loading} />
        </Paper>
      </Grid>

      <Grid item xs={12} sx={{ mt: 0, mb: 0 }}>
        <Paper sx={{ width: '100%', mb: 0 }}>
          <CryptoTable
            loading={loading}
            coins={coins}
            actions={{
              isEditTable: isEditTable,
              hasAction: true,
              remove: true,
              edit: true,
            }}
            setWallet={setWallet}
            handleAction={handleRowAction}
          />
        </Paper>
      </Grid>
    </>
  )
}
export default memo(CryptoList)
