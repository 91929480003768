import React, { useEffect, useState } from 'react'
import Title from '../../src/components/Title'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import Button from '@mui/material/Button'

import { TextareaAutosize } from '@mui/base/TextareaAutosize'

import { createWorker } from 'tesseract.js'
import setBill from '../../src/actions/setBill'
import { useDispatch } from 'react-redux'
import resetBill from '../../src/actions/resetBill'
import CircularSpinner from './atoms/CircularSpinner'
import { getSwal } from '../utility/notifications'
import { Today } from '../utility/date'
import { validateDate } from '../utility/validations'

import {
  getItemPrices,
  getName,
  getDate,
} from '../../src/utility/dataExtract/bill'

export default function PicReader(props) {
  const dispatch = useDispatch()

  const [fileImage, setFileImage] = useState('')
  const [text, setText] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [spinnerImage, setSpinnerImage] = useState(false)

  const handleFileUpload = async (event) => {
    resetValues()
    setSpinnerImage(true)
    const file = event.target.files[0]
    const reader = new FileReader()
    try {
      reader.onloadend = () => {
        setFileImage(reader.result)
      }
      reader.readAsDataURL(file)
    } catch (error) {
      getSwal('Error', 'There was an error uploading the file')
    }
    setSpinnerImage(false)
  }

  const convertImageToText = async () => {
    setSpinner(true)
    try {
      if (fileImage) {
        const worker = await createWorker()
        await worker.load()
        await worker.loadLanguage('eng')
        await worker.initialize('eng')
        const { data } = await worker.recognize(fileImage)

        setText(data.text)
        const { totalNumber } = getItemPrices(data.lines)
        if (data.lines.length < 4) {
          return false
        }
        const name = getName(data.lines)
        const date = getDate(data.text)

        const obj = { name, amount: totalNumber }

        const val = validateDate(date)

        obj.date = val ? date : Today.YYYYMMDD

        dispatch(setBill(obj))
      }
    } catch (e) {
      console.error(`Worker failed: `, e)
    }
    setSpinner(false)
  }

  useEffect(() => {
    convertImageToText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileImage])

  const resetValues = () => {
    setFileImage('')
    setText('')
    dispatch(resetBill())
  }

  useEffect(() => {
    //resetValues() //
    setFileImage('')
    setText('')
  }, [])

  return (
    <>
      <Title>Upload Your Bill Picture</Title>
      <Stack
        component="main"
        maxWidth="xs"
        sx={{
          ml: 0,
          pl: 0,
          display: 'block',
          // flexDirection: 'column',
        }}
      >
        {spinnerImage ? (
          <Box sx={{ display: 'block', pl: 0, ml: '50px' }}>
            <CircularSpinner />{' '}
          </Box>
        ) : (
          ''
        )}
        <Stack sx={{ ml: '0', pl: '0', p: 0, maxWidth: '400px' }}>
          {fileImage ? (
            <img src={fileImage} alt="invoice pic" />
          ) : (
            ''
            // <img src={placeholder} alt="placeholder-img" />
          )}
        </Stack>
        <Box sx={{ mt: 2 }}>
          <label htmlFor="file" sx={{ ml: 0, pl: 0 }}>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              component="span"
            >
              Upload
            </Button>
            <input
              id="file"
              hidden
              accept="image/*"
              type="file"
              multiple
              onChange={handleFileUpload}
            />
          </label>
          <label htmlFor="reset" sx={{ ml: 0, pl: 0 }}>
            <Button
              color="grey"
              variant="contained"
              component="span"
              id="reset"
              onClick={resetValues}
            >
              Reset
            </Button>
          </label>
        </Box>
        <br />
        <Stack sx={{ ml: '0', pl: '0', p: 0, maxWidth: '400px' }}>
          {spinner ? (
            <Stack sx={{ ml: 0, pl: '70px' }}>
              <CircularSpinner />{' '}
            </Stack>
          ) : (
            ''
          )}
          {text ? <TextareaAutosize defaultValue={text} maxRows="10" /> : ''}
        </Stack>
      </Stack>
    </>
  )
}
