import React from 'react'
import { TransitionGroup } from 'react-transition-group'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import DeleteIcon from '@mui/icons-material/Delete'

const colorChange = (originalCategories, category) => {
  return originalCategories.includes(category) ? 'default' : 'green'
}

function renderItem(originalCategories, { item, handleRemoveCategory }) {
  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          title="Delete"
          onClick={() => handleRemoveCategory(item)}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText
        primary={item}
        sx={{ color: colorChange(originalCategories, item) }}
      />
    </ListItem>
  )
}

export default function CategoryList(props) {
  const data = []
  props.categories.forEach((category, i) => {
    data.push({ name: category, rank: ++i })
  })

  return (
    <>
      <List
        sx={{
          mt: 1,
          maxWidth: 360,
          maxHeight: 400,
          position: 'relative',
          overflow: 'auto',
        }}
        xs={12}
        md={2}
        lg={1}
      >
        <TransitionGroup>
          {props.categories.map((item) => (
            <Collapse key={item}>
              {renderItem(props.originalCategories, {
                item,
                handleRemoveCategory: props.handleRemoveCategory,
              })}
            </Collapse>
          ))}
        </TransitionGroup>
      </List>
    </>
  )
}
