export const headCells = [
  // {
  //   column: 'checkbox',
  //   name: 'checkbox',
  //   type: 'checkbox',
  //   numeric: false,
  //   padding: 'checkbox',
  //   align: 'left',
  //   label: '',
  // },
  {
    column: 'symbol',
    name: 'symbol',
    type: 'picture',
    numeric: false,
    padding: 'normal',
    align: 'left',
    label: 'Symbol',
    sx: { pl: '40px', width: '100px' },
  },
  {
    column: 'name',
    name: 'name',
    type: 'text',
    numeric: false,
    padding: 'normal',
    align: 'left',
    label: 'Name',
    isEdit: false,
    //sx: { width: '100px' },
  },
  {
    column: 'marketCap',
    name: 'marketCap',
    type: 'amount',
    numeric: true,
    padding: 'normal',
    align: 'left',
    label: 'Market Cap',
    isEdit: false,
  },
  {
    column: 'price',
    name: 'price',
    type: 'amount',
    numeric: true,
    padding: 'normal',
    align: 'left',
    label: 'Price',
    isEdit: false,
  },
  {
    column: 'qty',
    name: 'qty',
    type: 'amount',
    numeric: true,
    padding: 'normal',
    align: 'left',
    label: 'Quantity',
    isEdit: true,
  },
  {
    column: 'total',
    name: 'total',
    type: 'amount',
    numeric: true,
    padding: 'normal',
    align: 'left',
    label: 'Total',
    isEdit: false,
  },
]
