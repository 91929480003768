import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function CheckboxesTags(props) {
  const data = props.data
  return (
    <>
      <Autocomplete
        disablePortal
        multiple
        id="checkboxes-tags-demo"
        options={data}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        onChange={(e, option) => props.updateList(option)}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            <img
              src={option.iconUrl}
              alt={option.name}
              height="22"
              width="22"
              style={{ marginRight: 10 }}
            />
            {option.name}{' '}
            {option.name !== option.symbol ? `(${option.symbol})` : ''}
          </li>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField {...params} label={props.label} placeholder="Favorites" />
        )}
      />
    </>
  )
}
