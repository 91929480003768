import React from 'react'
import { Tooltip } from '@mui/material'
import { IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded'

export default function CryptoListButtons(props) {
  return (
    <>
      {props.isEditTable ? (
        <Tooltip title={'Cancel'} placement="top-start" arrow>
          <IconButton
            sx={{ ml: 0 }}
            edge="end"
            aria-label="cancel"
            // title="Cancel"
            onClick={(e) => props.handleCancel(e)}
          >
            <DoNotDisturbAltRoundedIcon color="warning" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={'Edit'} placement="top-start" arrow>
          <IconButton
            sx={{ ml: 0 }}
            edge="end"
            aria-label="edit"
            // title="Edit"
            onClick={(e) => props.setIsEditTable(true)}
          >
            <EditIcon color="primary" />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={'Add'} placement="top-start" arrow>
        <span>
          <IconButton
            sx={{ ml: 0 }}
            edge="end"
            aria-label="Add"
            // title="Add"
            onClick={(e) => props.handleAdd(e, props)}
            disabled={!props.isEditTable}
          >
            <AddIcon color={!props.isEditTable ? 'grey' : 'info'} />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={'Save'} placement="top-start" arrow>
        <span>
          <IconButton
            sx={{ ml: 0 }}
            edge="end"
            aria-label="save"
            // title="Edit"
            onClick={(e) => props.handleSave(e)}
            disabled={!props.isEditTable}
          >
            <SaveIcon color={!props.isEditTable ? 'gray' : 'info'} />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={'Delete'} placement="top-start" arrow>
        <span>
          <IconButton
            sx={{ mr: 0, ml: 1 }}
            edge="end"
            aria-label="delete"
            // title="Delete"
            onClick={(e) => props.handleRemove(e)}
            disabled={!props.isEditTable}
          >
            <DeleteIcon color={!props.isEditTable ? 'gray' : 'error'} />
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}
