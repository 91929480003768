import React, { useState, useRef, memo } from 'react'
import { TableCell, TableRow } from '@mui/material'

import TablecellInteractive from './TableCellInteractive'
import TbActionElement from '../Actions/TbActionElement'

function TbTableRows(props) {
  const [isEdit, setIsEdit] = useState(false)
  const [action, setAction] = useState('')
  const value = useRef(null)
  const prev = useRef(null)
  const column = useRef(null)

  const handleAction = (data) => {
    if (data.action === 'save') {
      props.setWallet({
        ...props.row,
        [column.current]: isNaN(value.current)
          ? value.current
          : parseFloat(value.current),
        [column.current + 'Prev']: isNaN(prev.current)
          ? prev.current || ''
          : parseFloat(prev.current) || 0,
        action: data.action,
      })
    }
    data.row = { ...props.row }
    props.handleAction(data)
    setIsEdit(data.isEdit)
    setAction(data.action)
  }

  const handleValue = (data) => {
    prev.current = data.prev
    value.current = data.value
    column.current = data.column
  }

  return (
    <TableRow key={props.rowIndex} hover>
      {props.tableCells?.map((field, i) => (
        <TablecellInteractive
          key={i}
          sx={props.tableCells.find((f) => f.column === field.column)?.sx}
          column={field.column}
          value={props.row[field.column]}
          type={field.type}
          row={props.row}
          mapValuesToColumns={props.mapValuesToColumns}
          isColEditable={
            props.tableCells.find((f) => f.column === field.column)?.isEdit
          }
          isEdit={isEdit}
          action={action}
          handleValue={handleValue}
        />
      ))}
      {props.actions.hasAction ? (
        <TableCell sx={{ width: '150px;' }}>
          <TbActionElement
            actions={props.actions}
            handleAction={handleAction}
          />
        </TableCell>
      ) : (
        ''
      )}
    </TableRow>
  )
}

export default memo(TbTableRows)
