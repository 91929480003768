import React, { memo } from 'react'
import Spinner from '../../../../../utility/Spinner/Spinner'
import { tableCells } from './tableCells'
import { headCells } from './headCells'
import { mapValuesToColumns } from './mapColumns'
import NoData from '../../../../../components/atoms/NoData'

import TbTableContainer from '../../../../../components/organisms/TbTableContainer'
import TbTableEdit from '../../TbTableEdit'

const CryptoTable = (props) => {
  if (props.loading) return <Spinner />
  if (!props.loading && !props.coins?.length) return <NoData />
  return (
    <TbTableContainer data={props.coins} loading={props.loading}>
      <TbTableEdit
        headCells={headCells}
        tableCells={tableCells}
        mapValuesToColumns={mapValuesToColumns}
        actions={props.actions}
        setWallet={props.setWallet}
        handleAction={props.handleAction}
      />
    </TbTableContainer>
  )
}

export default memo(CryptoTable)
