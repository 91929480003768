import React, { useState } from 'react'
import ComboBox from '../components/molecules/ComboBox'
import { useForm, FormProvider } from 'react-hook-form'
import { Button, TextField, Box } from '@mui/material'
import axios from 'axios'
import { getSwal } from '../utility/notifications'
import { useDispatch, useSelector } from 'react-redux'
import updateUser from '../actions/updateUser'
import config from '../configs/config'

import api from '../configs/api'
import apiCrypto from '../data/mock/apiCrypto.json'

export default function ComboBoxSection(props) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [openCryptoWallet, setOpenCryptoWallet] = useState(false)
  const [listCoins] = useState(apiCrypto.data)
  listCoins.forEach((coin) => (coin.id = coin.rank))

  const [name, setName] = useState('Default')
  const [list, setList] = useState([])
  const [disabledBtn, setDisabledBtn] = useState(props.disabledBtn)
  const methods = useForm()

  const openWalletEdit = (value) => {
    setOpenCryptoWallet(value)
    setList([])
  }
  const updateThisUser = async (formData) => {
    const resp = await axios.put(`${api.users.getAll}/${user._id}`, formData, {
      withCredentials: true,
    })
    return resp.data.data
  }

  const updateList = (coins) => {
    setList(coins)
    methods.setValue('coins', coins)
  }

  const getError = (methods, propsName) => {
    return !!methods.formState.errors[propsName]
  }

  const getHelpError = (methods, propsName) => {
    return methods.formState.errors[propsName] &&
      typeof methods.formState.errors[propsName].message !== 'undefined'
      ? methods.formState.errors[propsName].message
      : ''
  }

  const onSubmit = async (data) => {
    const dataCoins = []
    data.coins.forEach((coin) => {
      dataCoins.push({
        qty: 0,
        id: coin.rank,
        symbol: coin.symbol,
        name: coin.name,
        iconUrl: coin.iconUrl,
        price: coin.price,
        marketCap: coin.marketCap,
        total: 0,
      })
    })

    try {
      setDisabledBtn(true)
      let nextIndex = 1
      if (!user?.wallets?.length) {
        user.wallets = []
      } else {
        nextIndex = user.wallets[user.wallets.length - 1].id + 1
      }
      user.wallets.push({ id: nextIndex, name: data.name, coins: dataCoins })
      const userUpdated = await updateThisUser(user)
      dispatch(updateUser(userUpdated))
      setOpenCryptoWallet(false)
      methods.setValue('coins', '')
      methods.setValue('name', '')
      getSwal('Success', 'Your wallet has been saved', 'success')
      setList([])
      props.setTabIndex(user.wallets.length)
    } catch (error) {
      console.error('Error: ', error)
      getSwal(
        'Error',
        'There was an error attempting to save your transaction, please try again!'
      )
    }
    setDisabledBtn(false)
  }

  return (
    <>
      <Button
        variant="contained"
        component="button"
        color={openCryptoWallet ? 'grey' : 'primary'}
        sx={{ mt: 0, mb: 5 }}
        onClick={() => openWalletEdit(!openCryptoWallet)}
        disabled={
          disabledBtn || props.wallets.length >= config.limitCryptoWallet
        }
      >
        {openCryptoWallet ? 'Cancel' : 'Add New Wallet'}
      </Button>
      {!openCryptoWallet ? (
        ''
      ) : (
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <TextField
              required
              name="name"
              variant="standard"
              label="Wallet"
              {...methods.register('name', {
                required: 'Field is required',
              })}
              error={getError(methods, 'name')}
              helperText={getHelpError(methods, 'name')}
              autoComplete={name}
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ mb: 2 }}
            />

            <ComboBox label="Crypto" data={listCoins} updateList={updateList} />
            <Button
              type="submit"
              variant="contained"
              component="button"
              sx={{ mt: 2, mb: 3 }}
              disabled={list.length === 0}
            >
              Add Wallet
            </Button>
          </Box>
        </FormProvider>
      )}
    </>
  )
}
