import React, { useState } from 'react'
import TbCancelElement from './TbCancelElement'
import TbDeleteElement from './TbDeleteElement'
import TbEditElement from './TbEditElement'
import TbSaveElement from './TbSaveElement'
import TbAddElement from './TbAddElement'

export default function TbActionElement(props) {
  const [isEdit, setIsEdit] = useState(false)

  const handleEdit = (e, props, action) => {
    setIsEdit(true)
    props.handleAction({ ...props, isEdit: true, action })
  }

  const handleCancel = (e, props, action) => {
    setIsEdit(false)
    props.handleAction({ ...props, isEdit: false, action })
  }

  const handleSave = (e, props, action) => {
    setIsEdit(false)
    props.handleAction({ ...props, isEdit: false, action })
  }

  const handleRemove = (e, props, action) => {
    props.handleAction({ ...props, isEdit: false, action })
  }

  if (props?.actions?.hasAction) {
    return (
      <>
        <TbDeleteElement
          actions={props.actions}
          isEdit={isEdit}
          handleRemove={(e) => handleRemove(e, props, 'delete')}
        />
        <TbEditElement
          actions={props.actions}
          isEdit={isEdit}
          column={props.id}
          handleEdit={(e) => handleEdit(e, props, 'edit')}
        />
        <TbCancelElement
          actions={props.actions}
          isEdit={isEdit}
          handleCancel={(e) => handleCancel(e, props, 'cancel')}
        />
        <TbSaveElement
          actions={props.actions}
          isEdit={isEdit}
          handleSave={(e) => handleSave(e, props, 'save')}
        />
      </>
    )
  }
  return <></>
}
