import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import api from '../configs/api'
import axios from 'axios'
import CategoryForm from '../components/molecules/CategoryForm'
import updateCategories from '../actions/updateCategories'
import CategoryList from '../components/molecules/CategoryList'
import { Grid, Button, Paper } from '@mui/material'
import { getSwal } from '../utility/notifications'
import { fetchUser } from '../utility/requests'
import NoData from '../components/atoms/NoData'
import Spinner from '../utility/Spinner/Spinner'
import Swal from 'sweetalert2'

export default function PaymentMethod() {
  const dispatch = useDispatch()
  const [categories, setCategories] = useState([])
  const [originalCategories, setOriginalCategories] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fectchCategoriesReq = async () => {
      setLoading(true)
      const user = await fetchUser()
      const categories = user.sources
      setLoading(false)
      setCategories(categories)
      setOriginalCategories(categories)
      dispatch(updateCategories(categories))
    }
    fectchCategoriesReq()
  }, [dispatch])

  const handleRemoveCategory = async (item) => {
    const result = await Swal.fire({
      title: 'Delete Payment Method',
      text: `You are about to delete: ${item}`,
      icon: 'warning',
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      closeOnConfirm: false,
    })
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      setCategories((prev) => [...prev.filter((i) => i !== item)])
    }
  }
  //
  const saveCategories = async (e, originalCategories, categories) => {
    const intersection = categories.filter(
      (x) => !originalCategories.includes(x)
    )
    const addToCategory = (acc, item) => {
      acc.push({ name: item, description: item })
      return acc
    }
    const arr = intersection.reduce(addToCategory, [])

    try {
      await axios.post(`${api.categories.category}`, arr, {
        withCredentials: true,
      })
      if (!intersection.length) {
        return getSwal('Error', 'Please add at least one payment method')
      }
      getSwal(
        'Payment Method',
        `${intersection.join(',')} successfully saved`,
        'success'
      )
    } catch (error) {
      console.error(`Table error: `, error.response.data)
      getSwal('Error', error.response.data.error)
    }
  }

  if (loading) return <Spinner />
  if (!categories.length) return <NoData />
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <h1>Payment Method</h1>
      </Grid>
      <br />
      <br />
      <Grid item xs={12} md={12} lg={12}>
        <CategoryForm
          label="Payment Method"
          categories={categories}
          updateBasketCategories={setCategories}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Paper sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column' }}>
          <CategoryList
            categories={categories}
            originalCategories={originalCategories}
            handleRemoveCategory={handleRemoveCategory}
          />
        </Paper>
        <Button
          onClick={(e) => saveCategories(e, originalCategories, categories)}
          variant="contained"
          sx={{ mt: '20px' }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  )
}
