import React from 'react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import MuiDrawer from '@mui/material/Drawer'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'

import {
  Box,
  Toolbar,
  List,
  Divider,
  IconButton,
  Container,
} from '@mui/material'
import detectMobile from '../hooks/detectMobile'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import {
  MainListItems,
  secondaryListItems,
  InvestmentListItems,
} from '../components/listItems'
import Copyright from './../utility/Copyright/Copyright'

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    color: theme.palette.secondary.main, //'#006B86',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
  '& .MuiListItemText-root': { color: theme.palette.secondary.main },
}))

const tileStyles = {
  style: {
    fontWeight: '600',
    fontSize: '17px',
    mb: 0,
    mt: 0,
    color: '#596d79',
  },
}

export default function Home(props) {
  const isMobile = detectMobile()
  const [open, setOpen] = React.useState(!isMobile)
  const toggleDrawer = (isMobile) => {
    if (isMobile) {
      setOpen(!isMobile)
    } else {
      setOpen(isMobile)
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            // px: [1],
          }}
        >
          <IconButton onClick={(isMobile) => toggleDrawer(isMobile)}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <Divider sx={{ mt: 2 }} />
          <ListItem sx={{ cursor: 'initial' }}>
            {isMobile ? (
              <ListItemIcon>
                <AccountBalanceWalletIcon sx={{ color: '#006B86' }} />
              </ListItemIcon>
            ) : (
              ''
            )}
            <ListItemText
              primary="Expenses"
              primaryTypographyProps={tileStyles}
            />
          </ListItem>
          <Divider sx={{ mt: 1 }} />

          {MainListItems}
          <Divider sx={{ mt: 2 }} />
          <ListItem sx={{ cursor: 'initial' }}>
            {isMobile ? (
              <ListItemIcon>
                <TrendingUpIcon sx={{ color: '#006B86' }} />
              </ListItemIcon>
            ) : (
              ''
            )}
            <ListItemText
              primary="Investements"
              primaryTypographyProps={tileStyles}
            />
          </ListItem>
          <Divider sx={{ mt: 1 }} />
          {InvestmentListItems}
          <Divider sx={{ mt: 2 }} />
          <ListItem sx={{ cursor: 'initial' }}>
            {isMobile ? (
              <ListItemIcon>
                <ManageAccountsIcon sx={{ color: '#006B86' }} />
              </ListItemIcon>
            ) : (
              ''
            )}
            <ListItemText
              primary="User Configuration"
              primaryTypographyProps={tileStyles}
            />
          </ListItem>
          <Divider sx={{ mt: 1 }} />
          {secondaryListItems}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          // height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {/**START OF TEMPLATE */}

          {props.children}

          {/**END TEMPLATE */}

          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  )
}
