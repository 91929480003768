import React from 'react'
import axios from 'axios'
import api from '../../configs/api'
import { Container, Box, Button, Typography } from '@mui/material'
import logoutAction from '../../actions/logoutAction'
import { useDispatch } from 'react-redux'

export default function VerifyEMailModal(props) {
  const dispatch = useDispatch()
  const logOut = async () => {
    dispatch(logoutAction())
    await axios.post(api.auth.logout, {}, { withCredentials: true })
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h2" variant="h4">
            Please verify your email
          </Typography>

          <Typography
            component="p"
            variant="h5"
            sx={{
              mt: 3,
              mb: 2,
              fontSize: '1rem',
              fontWeight: '400',
              color: 'auto',
            }}
          >
            Please check your email and click the link
          </Typography>
          <Box
            sx={{
              mt: 4,
              mb: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{ mr: '10px' }}
              variant="contained"
              color="primary"
              onClick={(e) => props.resendToken(e, props.user)}
            >
              Resend Token
            </Button>
            <Button
              color="grey"
              variant="contained"
              onClick={logOut}
              sx={{ mr: 1 }}
            >
              Log Out
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}
