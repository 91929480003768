import React, { useState, useEffect, cloneElement } from 'react'
import { TablePagination } from '@mui/material'
import { transFormObjectToArray } from '../../utility/objectArrays'
import Spinner from '../../utility/Spinner/Spinner'
import config from '../../configs/config'

const TbTableContainer = (props) => {
  const getRowOrders = (data) => {
    if (!data) {
      return []
    }
    const dataT = transFormObjectToArray(data)
    if (typeof dataT !== 'object' || !dataT.length) {
      return []
    }
    return dataT
  }

  const [rows, setRows] = useState([])
  const [counter, setCounter] = useState(0)
  const [originalRows, setOriginalRows] = useState(getRowOrders(props.data))
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(config.defaultRowsPerPage)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('total')

  const Component = (props) => {
    return cloneElement(props.children, {
      rows: props.rows,
      page: props.page,
      rowsPerPage: props.rowsPerPage,
      originalRows: props.originalRows,
      setOrderBy: props.setOrderBy,
      setOrder: props.setOrder,
      order: props.order,
      orderBy: props.orderBy,
    })
  }

  const handleChangePage = (event, newPage) => {
    nextRowData(newPage)
    setPage(newPage)
  }

  const nextRowData = (newPage) => {
    const startIndex = newPage * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    const newRows = originalRows.slice(startIndex, endIndex)
    if (newRows.length) {
      setRows(newRows)
    }
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value)
    setPage(0)
  }

  useEffect(() => {
    setRowsPerPage(rowsPerPage)
    const originalRows = getRowOrders(props.data)
    setCounter(originalRows.length)
    setOriginalRows(originalRows)
    const newRows = originalRows.slice(0, rowsPerPage)
    setRows(newRows)
  }, [rowsPerPage, props.data])
  if (props.loading) return <Spinner />

  return (
    <React.Fragment>
      <Component
        children={props.children}
        rows={rows}
        originalRows={originalRows}
        page={page}
        name={props.name}
        rowsPerPage={rowsPerPage}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        order={order}
        orderBy={orderBy}
      />
      <TablePagination
        rowsPerPageOptions={[config.defaultRowsPerPage, 25, 50, 100]}
        component="div"
        count={counter}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  )
}
export default TbTableContainer
