import * as React from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import './listItems.css'

import { Link } from 'react-router-dom'
import {
  primaryItemsList,
  secondaryItemsList,
  investmentItemsList,
} from '../templates/MenuItemsList'

const MenuLists = (props) => {
  let list = []
  props.items.forEach((item, i) => {
    list.push(
      <Link to={`${item.route}`} key={i}>
        <ListItemButton>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      </Link>
    )
  })
  return list
}

export const InvestmentListItems = (
  <React.Fragment>
    <MenuLists items={investmentItemsList} />
  </React.Fragment>
)

export const MainListItems = (
  <React.Fragment>
    <MenuLists items={primaryItemsList} />
  </React.Fragment>
)

export const secondaryListItems = (
  <React.Fragment>
    <MenuLists items={secondaryItemsList} />
  </React.Fragment>
)
