import React from 'react'

import { Doughnut } from 'react-chartjs-2'
import { getVibrantColors } from '../utility/theme'
import { useTheme } from '@mui/material/styles'
import NoData from '../components/atoms/NoData'

import Spinner from '../utility/Spinner/Spinner'

import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: true,
      position: 'right',
    },
  },
}

export default function PieChartComp(props) {
  const theme = useTheme()
  const colors = getVibrantColors(theme)

  const data = {
    labels: props.data.labels,
    datasets: [
      {
        data: props.data.values,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  }
  const style = {
    width: '70%',
  }

  const length = data?.datasets?.[0]?.data?.length || 0
  if (props.loading) {
    return (
      <>
        <div style={{ ...style, paddingTop: '10px' }}>
          <Spinner />
        </div>
      </>
    )
  }
  return (
    <React.Fragment>
      <div style={{ width: '90%' }}>
        {length ? (
          <Doughnut sx={props.sx} options={options} data={data} />
        ) : (
          <NoData />
        )}
      </div>
    </React.Fragment>
  )
}
